/* eslint-disable no-restricted-globals */
/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';

import {
  LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG,
  LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS,
  LIST_ENROLLMENT_PLANNING_UTILISATION,
} from '../models/enrolmentPlanningModel';
import {
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING,
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS,
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR,
  GET_ENROLLMENT_PLANNING_UTILISATION_LOADING,
  GET_ENROLLMENT_PLANNING_UTILISATION_SUCCESS,
  GET_ENROLLMENT_PLANNING_UTILISATION_ERROR,
  GET_LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';

const getEnrolmentPlanningUtilisationLoading = () => ({
  type: GET_ENROLLMENT_PLANNING_UTILISATION_LOADING,
  value: {
    inProgress: true,
    error: null,
    data: [],
  },
});

const getEnrolmentPlanningUtilisationSuccess = data => ({
  type: GET_ENROLLMENT_PLANNING_UTILISATION_SUCCESS,
  value: {
    inProgress: false,
    data,
  },
});

const getEnrolmentPlanningUtilisationError = error => ({
  type: GET_ENROLLMENT_PLANNING_UTILISATION_ERROR,
  value: {
    inProgress: false,
    error,
  },
});

const getListEnrollmentPlanningRequestsLoading = () => ({
  type: GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING,
  value: {
    inProgress: true,
    error: null,
    data: [],
  },
});

const getListEnrollmentPlanningRequestsSuccess = data => ({
  type: GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS,
  value: {
    inProgress: false,
    data,
  },
});

const getListEnrollmentPlanningRequestsError = error => ({
  type: GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR,
  value: {
    inProgress: false,
    error,
  },
});

const dispatchListActivityLogEnrollmentPlaning = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: GET_LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getEnrolmentPlanningUtilisation = ({
  centreID,
  year,
}) => async dispatch => {
  dispatch(getEnrolmentPlanningUtilisationLoading());
  try {
    const res = await SkApolloRequest({
      params: {
        query: LIST_ENROLLMENT_PLANNING_UTILISATION,
        variables: { centreID, year },
      },
      type: 'query',
    });
    if (res.success) {
      const data = get(res, 'data.getEnrolmentPlanningUtilisation');
      dispatch(getEnrolmentPlanningUtilisationSuccess(data));
    } else {
      dispatch(
        getEnrolmentPlanningUtilisationError(get(res, 'error.0.message'))
      );
    }
  } catch (ex) {
    dispatch(getEnrolmentPlanningUtilisationError(ex.message));
  }
};

export const getListEnrollmentPlanningRequests = filter => async dispatch => {
  dispatch(getListEnrollmentPlanningRequestsLoading());
  try {
    const res = await SkApolloRequest({
      params: {
        query: LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS,
        variables: { filter },
      },
      type: 'query',
    });
    if (res.success) {
      const data = get(res, 'data.listEnrollmentPlanningChangeRequests');
      dispatch(getListEnrollmentPlanningRequestsSuccess(data));
    } else {
      dispatch(
        getListEnrollmentPlanningRequestsError(get(res, 'error.0.message'))
      );
    }
  } catch (ex) {
    dispatch(getListEnrollmentPlanningRequestsError(ex.message));
  }
};

export const getListEnrollmentPlanningActivityLog = (
  schoolID,
  pagination,
  filter
) => async dispatch => {
  dispatchListActivityLogEnrollmentPlaning(dispatch);
  try {
    const res = await SkApolloRequest({
      params: {
        query: LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG,
        variables: { schoolID, pagination, filter },
      },
      type: 'query',
    });
    if (res.success) {
      const data = get(res, 'data.capacityDraftsByFkSchool');
      dispatchListActivityLogEnrollmentPlaning(dispatch, false, data);
    } else {
      dispatch(
        dispatchListActivityLogEnrollmentPlaning(
          dispatch,
          false,
          get(res, 'error.0.message')
        )
      );
    }
  } catch (ex) {
    dispatch(getListEnrollmentPlanningRequestsError(ex.message));
  }
};
