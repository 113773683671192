import React from 'react';
import { Trans } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { StyledHyperLink } from 'components/common/style';
import Button from 'components/common/Button';
import { BENEFIT_OF_LINKPASS } from 'routes/constants';

const Linkpass = props => {
  const {
    onLinkpassBtnClick,
    onLoginWithEmail,
    isNewParent,
    isConsentECDA,
  } = props;

  const renderLoginLinkpassUI = () => {
    if (isNewParent) {
      return (
        <>
          <Grid className="s7t-margin-bottom10">
            <Button
              type="button"
              button="primary"
              fullWidth
              onClick={() => onLinkpassBtnClick('loginWithLinkpass')}
              className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
              disabled={!isConsentECDA}
            >
              <Trans i18nKey="auth.loginRegisterWithLinkpass" />
            </Button>
          </Grid>
          <Grid item xs={12} className="s7t-margin-bottom10">
            <Button
              type="button"
              button="secondary"
              fullWidth
              onClick={() => onLoginWithEmail()}
              className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
              disabled={!isConsentECDA}
            >
              <Trans i18nKey="auth.registerWithEmail" />
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            className="s7t-margin-bottom10"
            style={{ textAlign: 'center' }}
          >
            <StyledHyperLink to={BENEFIT_OF_LINKPASS}>
              <Trans i18nKey="auth.benefitsOfLinkpass" />
            </StyledHyperLink>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <Button
            type="button"
            button="primary"
            fullWidth
            onClick={() => onLinkpassBtnClick('loginWithLinkpass')}
            className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
          >
            <Trans i18nKey="auth.loginWithLinkpass" />
          </Button>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: '24px' }}>
          <Button
            type="button"
            button="secondary"
            fullWidth
            onClick={() => onLoginWithEmail()}
            className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
          >
            <Trans i18nKey="auth.loginWithEmail" />
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          justify="center"
          className="s7t-margin-bottom10"
          style={{ textAlign: 'center' }}
        >
          <StyledHyperLink to={BENEFIT_OF_LINKPASS}>
            <Trans i18nKey="auth.benefitsOfLinkpass" />
          </StyledHyperLink>
        </Grid>
      </>
    );
  };

  return <>{renderLoginLinkpassUI()}</>;
};

export default Linkpass;
