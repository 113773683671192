import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import Button from './Button';
import Text from './Text';
import { ErrorHelperText } from './HelperComponents';
import Loader from './Loader';

const Modal = props => {
  const {
    isOpen,
    dialogTitle,
    loading,
    dialogContent,
    buttonPrimaryName,
    buttonSecondaryName,
    buttonSize,
    showClose,
    hideBtns,
    hideTitle,
    secondaryButtonDisabled,
    primaryButtonDisabled,
    errors,
    onClose,
    styleName,
    hideSecondaryBtn,
    onSecondaryClick,
    onPrimaryClick,
    contentStyle,
    errorContainerStyle,
    showLoader,
    actionContainerClass,
    buttonPrimaryVariant,
    buttonSecondaryVariant,
    customJustifyContent,
    verticalButton,
    hidePrimaryBtn,
    hideThirdBtn,
    onThirdClick,
    thirdButtonDisabled,
    buttonThirdName,
    dialogTitleClass,
    thirdButtonStyle,
    secondButtonStyle,
    primaryButtonStyle,
    titleClass,
    groupped2ndAnd3rdRightButtons,
    buttonSecondaryColor,
    extraFooterContent = null,
  } = props;
  return (
    <div>
      <Dialog
        open={isOpen}
        keepMounted
        {...props}
        className={styleName}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: { borderRadius: 8 },
        }}
      >
        {showClose && (
          <div className="w3-display-topright w3-margin-bottom">
            <IconButton aria-label="Close" onClick={onClose}>
              <CloseIcon style={{ color: '#dadada' }} />
            </IconButton>
          </div>
        )}
        {!hideTitle && dialogTitle && (
          <DialogTitle
            disableTypography
            className={`w3-no-padding w3-margin-top ${dialogTitleClass}`}
          >
            {typeof dialogTitle === 'string'
              ? dialogTitle.split('\n').map(eachLine => (
                  <Text
                    key={eachLine}
                    fontSize="26px"
                    extraBold
                    className={titleClass}
                    color="black"
                  >
                    {eachLine}
                  </Text>
                ))
              : dialogTitle}
          </DialogTitle>
        )}
        <DialogContent className={contentStyle}>
          {typeof dialogContent === 'string' ? (
            <DialogContentText id="alert-dialog-slide-description">
              {dialogContent}
            </DialogContentText>
          ) : (
            dialogContent
          )}
          <div className={errorContainerStyle}>
            <ErrorHelperText error={errors} />
          </div>
        </DialogContent>
        {!hideBtns && !verticalButton && !groupped2ndAnd3rdRightButtons && (
          <DialogActions
            className={actionContainerClass || 'w3-padding'}
            style={{ justifyContent: customJustifyContent }}
          >
            {extraFooterContent}
            {showLoader ? (
              <Loader key="loader" style={{ width: '20px', height: '20px' }} />
            ) : (
              <>
                {!hideThirdBtn && (
                  <Button
                    onClick={onThirdClick}
                    button="secondary"
                    size={buttonSize}
                    disabled={thirdButtonDisabled || loading}
                    style={thirdButtonStyle}
                  >
                    {buttonThirdName}
                  </Button>
                )}
                {!hideSecondaryBtn && (
                  <Button
                    button={buttonSecondaryVariant || 'secondary'}
                    onClick={onSecondaryClick}
                    size={buttonSize}
                    className="w3-margin"
                    disabled={secondaryButtonDisabled || loading}
                    color={buttonSecondaryColor}
                    style={secondButtonStyle}
                  >
                    {buttonSecondaryName}
                  </Button>
                )}
                {!hidePrimaryBtn && (
                  <Button
                    name={buttonPrimaryName}
                    button={buttonPrimaryVariant}
                    onClick={onPrimaryClick}
                    size={buttonSize}
                    className="w3-margin"
                    disabled={primaryButtonDisabled || loading}
                    loading={loading}
                    style={primaryButtonStyle}
                  >
                    {buttonPrimaryName}
                  </Button>
                )}
              </>
            )}
          </DialogActions>
        )}
        {verticalButton && !hideBtns && (
          <DialogActions
            className={actionContainerClass || 'w3-padding'}
            style={{ justifyContent: customJustifyContent }}
          >
            {showLoader ? (
              <Loader key="loader" style={{ width: '20px', height: '20px' }} />
            ) : (
              <Grid container>
                {!hideSecondaryBtn && (
                  <Grid item xs={12} className="w3-center">
                    <Button
                      onClick={onSecondaryClick}
                      button="secondary"
                      size={buttonSize}
                      disabled={secondaryButtonDisabled || loading}
                    >
                      {buttonSecondaryName}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} className="w3-center">
                  <Button
                    button={buttonPrimaryVariant}
                    onClick={onPrimaryClick}
                    size={buttonSize}
                    className="w3-margin"
                    disabled={primaryButtonDisabled || loading}
                    loading={loading}
                  >
                    {buttonPrimaryName}
                  </Button>
                </Grid>
              </Grid>
            )}
          </DialogActions>
        )}
        {!hideBtns && !verticalButton && groupped2ndAnd3rdRightButtons && (
          <DialogActions
            className={actionContainerClass || 'w3-padding'}
            style={{ justifyContent: customJustifyContent }}
          >
            {extraFooterContent}
            {showLoader ? (
              <Loader key="loader" style={{ width: '20px', height: '20px' }} />
            ) : (
              <>
                {!hideSecondaryBtn && (
                  <Button
                    button={buttonSecondaryVariant || 'secondary'}
                    onClick={onSecondaryClick}
                    size={buttonSize}
                    disabled={secondaryButtonDisabled || loading}
                    style={secondButtonStyle}
                  >
                    {buttonSecondaryName}
                  </Button>
                )}
                {!hideThirdBtn && (
                  <div>
                    <Button
                      onClick={onThirdClick}
                      button="secondary"
                      size={buttonSize}
                      disabled={thirdButtonDisabled || loading}
                      style={thirdButtonStyle}
                    >
                      {buttonThirdName}
                    </Button>
                  </div>
                )}
                {!hidePrimaryBtn && (
                  <div>
                    <Button
                      button={buttonPrimaryVariant}
                      onClick={onPrimaryClick}
                      size={buttonSize}
                      disabled={primaryButtonDisabled || loading}
                      loading={loading}
                      style={primaryButtonStyle}
                    >
                      {buttonPrimaryName}
                    </Button>
                  </div>
                )}
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

Modal.defaultProps = {
  onSecondaryClick: () => {},
  onPrimaryClick: () => {},
  onThirdClick: () => {},
  buttonPrimaryName: 'Okay',
  buttonSecondaryName: 'Cancel',
  styleName: '',
  fullWidth: false,
  buttonSize: 'medium',
  customJustifyContent: 'center',
  hideThirdBtn: true,
  errorContainerStyle: 'w3-center',
};

Modal.propTypes = {
  dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  dialogContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  buttonSize: PropTypes.string,
  buttonPrimaryName: PropTypes.string,
  buttonSecondaryName: PropTypes.string,
  styleName: PropTypes.string,
  onSecondaryClick: PropTypes.func,
  onPrimaryClick: PropTypes.func,
  onThirdClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  customJustifyContent: PropTypes.string,
  hideThirdBtn: PropTypes.bool,
  errorContainerStyle: PropTypes.string,
};

export default Modal;
