import axios from 'axios';
import { get } from 'lodash';
import { HOMEPAGE, MAINTENANCE } from 'routes/constants';
import { checkNetworkStatus } from 'check-network-status';
import { handleResponse } from '../../utils';
import LocalStore from '../../utils/localStorage';
import { handleErrorMsgInterceptor } from '../../utils/apolloUtils';

export default async config => {
  const headers = {};
  const userToken = LocalStore.getValue('userToken');
  const API_URL = process.env.REACT_APP_REACT_API_URL;
  const MAINTENANCE_PAGE_ENABLED =
    process.env.REACT_APP_MAINTENANCE_PAGE_REDIRECTION_ENABLED;

  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  if (config.method === 'POST') {
    headers['content-type'] = 'application/json';
    config.data = JSON.stringify(config.data);
    config.timeout = 10000;
  }

  if (!config.url) {
    config.url = API_URL;
  }

  if (!config.method) {
    config.method = 'GET';
  }

  config.headers = headers;
  try {
    const resp = await axios(config);
    if (window.location.pathname === MAINTENANCE) {
      window.location.pathname = HOMEPAGE;
    }
    return handleResponse(resp);
  } catch (ex) {
    const errorCode = get(ex, 'response.status');
    if (
      MAINTENANCE_PAGE_ENABLED === '1' &&
      (errorCode === 0 || errorCode === 502)
    ) {
      checkNetworkStatus().then(networkStatus => {
        if (networkStatus)
          if (window.location.pathname !== MAINTENANCE) {
            window.location.pathname = MAINTENANCE;
          }
      });
    } else {
      const errorMsg = get(ex, 'message');
      const requestData = get(ex, 'config.data');

      handleErrorMsgInterceptor(errorCode, errorMsg, requestData);

      return handleResponse({
        data: {
          errors: [ex.message],
        },
      });
    }
  }
};
